import { Box, Stack, TableCell, Typography } from '@mui/material';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import useLocales from 'hooks/useLocales';
import EmptyIconButton from 'components/ui/buttons/EmptyIconButton';
import MenuButton from 'components/ui/buttons/MenuButton';
import { useSelector } from 'storage';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import ModelUtils from 'utils/models/ModelUtils';
import IPermissionService from 'services/permission/IPermissionService';
import ServiceOrderedDetailsBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedDetailsBlock';
import ServiceOrderedClientPriceBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedClientPriceBlock';
import Label from 'components/ui/info/Label';
import { RECOMMENDATION_STATUS } from 'typings/models/order/order.enum';
import PopupContent from 'components/ui/popups/PopupContent';
import InfoIconButton from 'components/ui/buttons/InfoIconButton';
import AmountInputMemorized from 'components/ui/forms/AmountInputMemorized';
import { useFormik } from 'formik';
import useDI from 'hooks/useDI';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import useDebounce from 'hooks/useDebounce';

type Props = {
  orderSession: OrderContractSessionData;
  service: ServiceOrdered;
  editHandler: VoidFunction;
  deleteHandler: VoidFunction;
  permissionConfig: IPermissionService.PermissionConfigs;
};

function ServiceOrderedSessionListInfoItem({
  service,
  permissionConfig,
  orderSession,
  editHandler,
  deleteHandler,
}: Props) {
  const { storageActions } = useDI()
  const { permissions } = useSelector((state) => state.auth);
  const { translate } = useLocales();
  const [isDetailsOpened, setIsDetailsOpened] = useState(false);
  const snackbarErrorHandler = useSnackbarErrorHandler()

  const menuOptions = useMemo(() => {
    const menuOptions: MenuItemData[] = [];
    if (!orderSession) {
      return menuOptions;
    }
    if (permissions.can(MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE)) {
      menuOptions.push({
        title: translate('buttons.edit'),
        handler: editHandler,
        disabled: !permissions.serviceOrderedSessionBusinessPermissionCheck(
          MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE,
          service,
          orderSession,
          permissionConfig
        ),
      });
    }
    if (permissions.can(MODEL_PERMISSION.CAN_DELETE_ORDER_SERVICE)) {
      menuOptions.push({
        title: translate('buttons.delete'),
        handler: deleteHandler,
        disabled: !permissions.serviceOrderedSessionBusinessPermissionCheck(
          MODEL_PERMISSION.CAN_DELETE_ORDER_SERVICE,
          service,
          orderSession,
          permissionConfig
        ),
      });
    }

    return menuOptions;
  }, [service.updatedAt.getTime(), orderSession.updatedAt.getTime()]);

  const formState = useFormik({
    initialValues: service,
    onSubmit: async (values) => {
      try {
        const dto: CreateServiceOrderedRequestDTO = {
          ...service,
          price: {
            catalogPrice: service.price.clientPrice,
            currency: service.price.currency,
            value: service.price.clientPrice?.value as number,
            discount: service.price.discount,
            providerSaleBonus: service.price.providerSaleBonus
          },
          paid: service.isPaid,
          custom: service.isCustom,
          orderId: orderSession.id,
          quantity: values.quantity
        }
        await storageActions.models.serviceOrdered.update(
          { ...dto, id: service.id },
          PATH_BACKEND.orderSession.root + '/' + dto.orderId + '/' + PATH_BACKEND_PART.service.root
        )
        await storageActions.models.orderSession.getOrderSessionBackground(orderSession.id);
      } catch (error) {
        snackbarErrorHandler({ error })
      }
    }
  })

  const onQuantityChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      formState.setFieldValue('quantity', Number(event.target.value))
    } else {
      formState.setFieldValue('quantity', 1)
    }
  }, [formState])

  useDebounce({
    delay: 500,
    action: formState.submitForm,
    dependencies: [formState.values.quantity]
  })

  return (
    <>
      <TableCell>
        <Box>
          <Typography sx={{ flex: 1, whiteSpace: 'pre-line' }} variant="body2">
            {service.name}
          </Typography>
          {service.recommendationStatus === RECOMMENDATION_STATUS.required && (
            <Label color="default" sx={{ mr: 1, mt: 1 }}>
              {translate('entities.recommendations.type.required')}
            </Label>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <AmountInputMemorized
          minValue={1}
          fieldName='quantity'
          formState={formState}
          onChange={onQuantityChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
          }}
          style={{
            width: '130px'
          }}
        />
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <ServiceOrderedClientPriceBlock service={service} />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Stack direction="row">
          {menuOptions.length > 0 ? <MenuButton options={menuOptions} /> : <EmptyIconButton />}
          <InfoIconButton onClick={() => setIsDetailsOpened(!isDetailsOpened)} />
        </Stack>
      </TableCell>

      <PopupContent isOpened={isDetailsOpened} title={service.name} closeHandler={() => setIsDetailsOpened(false)}>
        <ServiceOrderedDetailsBlock service={service} defaultChildOpenState={true} />
      </PopupContent>
    </>
  );
}
export default React.memo(
  ServiceOrderedSessionListInfoItem,
  (pp, np) => ModelUtils.checkEquality(pp.orderSession, np.orderSession) && ModelUtils.checkEquality(pp.service, np.service)
);
