import { useSelector } from 'storage';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import AutocompleteMemorized from 'components/ui/forms/AutocompleteMemorized';
import useLocales from 'hooks/useLocales';
import FormProvider from 'components/ui/forms/FormProvider';
import { Button, Stack, Typography } from '@mui/material';
import useFilterDataInitialize from 'hooks/useFilterDataInitialize';
import MODEL_NAME from 'typings/models/_model.enum';
import { ORDER_DEFAULT_TYPE } from 'typings/models/order/orderType.enum';
import DynamicFieldEditState from 'components/features/dynamicField/DynamicFieldEditState';
import DynamicFieldUtils from 'utils/models/subEntities/DynamicFieldUtils';
import useDI from 'hooks/useDI';
import OrderSessionContractDataTableBlock from 'components/_dashboardPagesFeatures/order/add/session/OrderSessionContractDataTableBlock';
import { LoadingButton } from '@mui/lab';
import { OrderContractSessionDataRequestDTO } from 'typings/dto/order';
import PopupAction from 'components/ui/popups/PopupAction';
import OrderTablesListBlock from 'components/_dashboardPagesFeatures/order/info/OrderTablesListBlock';
import { OrderRegionIcon } from '../../../../ui/icons';
import { useLocation } from 'react-router';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';

type Props = {
  orderSession: OrderContractSessionData | null;
  enterprise: Enterprise;
  cancelHandler: VoidFunction;
  submitHandler: VoidFunction;
  cancelSessionHandler: VoidFunction;
  linkedOrderId: string | null;
  parentLinkedOrder?: Order;
  isLinked?: boolean;
};

function OrderAddFromSessionForm({
  orderSession,
  enterprise,
  submitHandler,
  cancelSessionHandler,
  linkedOrderId,
  parentLinkedOrder,
  isLinked,
}: Props) {
  const { currentUser } = useSelector((state) => state.auth);
  const { services, mappers, storageActions } = useDI();
  const { legalEntityAll, legalEntityAllAreLoading } = useSelector((state) => state.legalEntity);
  const { orderTypeAll, orderTypeAllAreLoading } = useSelector((state) => state.orderType);
  const { brandAll, brandAllAreLoading } = useSelector((state) => state.brand);
  const { shopAll, shopAllAreLoading } = useSelector((state) => state.shop);
  const { regionAll, regionAllAreLoading } = useSelector((state) => state.region);
  const handleFormErrors = useSnackbarErrorHandler();
  const { translate } = useLocales();
  const location = useLocation();
  const [isCancelBlockOpened, setIsCancelBlockOpened] = useState(false);
  const canModifyOrderNumber: boolean =
    !enterprise.settings.order.sessionSettings.multipleOrderCreation &&
    !enterprise.settings.order.sessionSettings.orderNumberAutomaticCreation;
  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];
  const [validationSchema, initialValues, dynamicFieldsConfigs] = useMemo(() => {
    const dynamicFieldsConfigs = enterprise.settings.order.customFieldsSettings.customFields.map((i) => i.dynamicField);
    const dynamicFields = orderSession
      ? Object.values(orderSession.customFields).map((i) => i.dynamicField)
      : parentLinkedOrder
      ? Object.values(parentLinkedOrder.customFields).map((i) => i.dynamicField)
      : [];

    const fieldIsRequiredText = translate('errors.fieldIsRequired');
    const validationSchema = Yup.object().shape({
      type: Yup.mixed().required(fieldIsRequiredText),
      legalEntity: Yup.mixed().required(fieldIsRequiredText),
      region: Yup.mixed().required(fieldIsRequiredText),
      brand: Yup.mixed().required(fieldIsRequiredText),
      shop: Yup.mixed().required(fieldIsRequiredText),
      number: Yup.string().test({
        message: fieldIsRequiredText,
        test: (thisValue) => Boolean(canModifyOrderNumber ? thisValue : true),
      }),
      ...DynamicFieldUtils.getValidationAddFormSubScheme(enterprise.settings.order.customFieldsSettings.customFields, services.language),
    });

    const initialValues = orderSession
      ? {
          type: orderSession.type || null,
          legalEntity: orderSession.legalEntity || null,
          region: orderSession.region || parentLinkedOrder?.region || null,
          brand: orderSession.brand || null,
          shop: orderSession.shop || null,
          ...DynamicFieldUtils.getInitialState(dynamicFields, dynamicFieldsConfigs),
          number: canModifyOrderNumber
            ? orderSession.number
              ? orderSession.number
              : orderSession.contracts.length > 0
              ? orderSession.contracts[0].contractId
                ? orderSession.contracts[0].contractId
                : orderSession.contracts[0].calculationId
              : ''
            : '',
        }
      : parentLinkedOrder
      ? {
          type: parentLinkedOrder.type || null,
          legalEntity: parentLinkedOrder.legalEntity || null,
          region: parentLinkedOrder?.region || null,
          brand: parentLinkedOrder.brand || null,
          shop: parentLinkedOrder.shop || null,
          number: '',
          ...DynamicFieldUtils.getInitialState(dynamicFields, dynamicFieldsConfigs),
        }
      : {
          type: null,
          legalEntity: null,
          region: null,
          brand: null,
          shop: null,
          number: '',
          ...DynamicFieldUtils.getInitialState(dynamicFieldsConfigs),
        };
    return [validationSchema, initialValues, dynamicFieldsConfigs];
  }, [orderSession?.id]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const { type, shop, brand, legalEntity, region, number } = values;

        if (!type || !legalEntity || !brand || !region || !shop) {
          throw new Error('Data is missing');
        }

        const dynamicFieldsDTORequests = dynamicFieldsConfigs.map((i) => mappers.subEntities.dynamicField.editStateToRequestDTO(i, values));
        const dynamicFieldsDTOs = await Promise.all(dynamicFieldsDTORequests);

        const dto: OrderContractSessionDataRequestDTO = {
          orderTypeId: type.id,
          legalEntityId: legalEntity.id,
          customFields: {},
          brandId: brand.id,
          regionId: region.id,
          shopId: shop.id,
          number: number,
          linkedOrderId: linkedOrderId ? linkedOrderId : orderSession ? orderSession.linkedOrderId : undefined,
        };

        dynamicFieldsDTOs.forEach((i) => (dto.customFields[i.technicalName] = i.value));
        if (orderSession) {
          await storageActions.models.orderSession.patch({ ...dto, id: orderSession.id });
          await storageActions.models.orderSession.getOrderSession(orderSession.id);
        } else {
          const id = await storageActions.models.orderSession.createSession({ ...dto });
          await storageActions.models.orderSession.getOrderSession(id);
        }

        submitHandler();
      } catch (error) {
        handleFormErrors({ error, callback: () => setSubmitting(false) });
      }
    },
  });

  useEffect(() => {
    formState.setValues((values) => {
      const newValues = { ...values };
      enterprise.settings.order.customFieldsSettings.customFields.forEach((customField) => {
        if (formState.values.type?.technicalName && !customField.showInSessionByOrderType[formState.values.type?.technicalName]) {
          DynamicFieldUtils.setFieldInitialValue(customField.dynamicField, newValues);
        }
      });
      return newValues;
    });
    return () => {
      formState.setErrors({});
    };
  }, [formState.values.type]);

  const tenantId = currentUser?.tenant.id || '';
  useFilterDataInitialize({
    [MODEL_NAME.orderType]: { tenantId },
    [MODEL_NAME.region]: { tenantId },
    [MODEL_NAME.legalEntity]: { tenantId },
    [MODEL_NAME.brand]: { tenantId },
    [MODEL_NAME.shop]: { tenantId },
  });

  // В форме создания основного заказа нужно убрать тип "Рекламация", так как рекламацию можно создать только если есть родительский
  const allowedOrderType = useMemo(() => {
    if (parentLinkedOrder && !isLinked) {
      if (lastSegment === ORDER_DEFAULT_TYPE.warranty) {
        formState.setFieldValue(
          'type',
          orderTypeAll.find((type) => type.technicalName === ORDER_DEFAULT_TYPE.warranty)
        );
      } else {
        formState.setFieldValue(
          'type',
          orderTypeAll.find((type) => type.technicalName === ORDER_DEFAULT_TYPE.complaint)
        );
      }
      return orderTypeAll;
    }
    return orderTypeAll.filter((type) => type.technicalName !== ORDER_DEFAULT_TYPE.complaint);
  }, [orderTypeAllAreLoading]);

  const shownCustomFieldIds = useMemo(() => {
    return enterprise.settings.order.customFieldsSettings.customFields
      .filter((i) => (formState.values.type ? i.showInSessionByOrderType[formState.values.type.technicalName] : false))
      .map((i) => i.dynamicField.id);
  }, [formState.values.type]);

  const isWarrantyWithParent = formState.values.type?.technicalName === ORDER_DEFAULT_TYPE.warranty && !!parentLinkedOrder;

  const disableType = formState.values.type?.technicalName === ORDER_DEFAULT_TYPE.complaint || isWarrantyWithParent;

  return (
    <Stack spacing={3}>
      {orderSession && orderSession.contracts.length > 0 && (
        <OrderSessionContractDataTableBlock orderSession={orderSession} formState={formState} />
      )}
      {orderSession && (
        <OrderTablesListBlock orderId={orderSession.id} orderUpdatedAt={orderSession.updatedAt} tables={orderSession.tables || []} />
      )}

      <FormProvider formState={formState}>
        <Typography variant="h6">{translate('pages.orderSessionEdit.otherData')}</Typography>
        {canModifyOrderNumber && <TextFieldMemorized fieldName="number" label={translate('entities.order.number')} formState={formState} />}
        <AutocompleteMemorized
          fieldName="type"
          label={translate('entities.orderType.entityName')}
          formState={formState}
          options={allowedOrderType}
          isLoading={orderTypeAllAreLoading}
          disabled={disableType}
        />
        <AutocompleteMemorized
          fieldName="legalEntity"
          label={translate('entities.legalEntity.entityName')}
          formState={formState}
          options={legalEntityAll}
          isLoading={legalEntityAllAreLoading}
        />
        <AutocompleteMemorized
          fieldName="region"
          label={translate('entities.region.entityName')}
          formState={formState}
          options={regionAll}
          isLoading={regionAllAreLoading}
          startAdornment={<OrderRegionIcon />}
        />
        <AutocompleteMemorized
          fieldName="brand"
          label={translate('entities.brand.entityName')}
          formState={formState}
          options={brandAll}
          isLoading={brandAllAreLoading}
        />
        <AutocompleteMemorized
          fieldName="shop"
          label={translate('entities.shop.entityName')}
          formState={formState}
          options={shopAll}
          isLoading={shopAllAreLoading}
        />
      </FormProvider>

      <fieldset style={{ border: 'none' }} disabled={formState.isSubmitting}>
        <Stack spacing={3}>
          {dynamicFieldsConfigs
            .filter((field) => shownCustomFieldIds.includes(field.id))
            .map((field) => (
              <DynamicFieldEditState key={field.id} keyProp={field.id} field={field} formState={formState} isAddForm />
            ))}
        </Stack>
      </fieldset>

      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="baseline">
        <Stack direction="row" spacing={2} flex={{ xs: 1, md: 0 }}>
          <LoadingButton
            onClick={formState.submitForm}
            variant="contained"
            color="primary"
            loading={formState.isSubmitting}
            sx={{ flexGrow: { xs: 1, md: 0 } }}
          >
            {translate('buttons.next')}
          </LoadingButton>
        </Stack>

        <Button variant="outlined" color="error" onClick={() => setIsCancelBlockOpened(true)}>
          {translate('buttons.cancel')}
        </Button>
        <PopupAction
          title={translate(`pages.orderAdd.cancelSessionTitle`)}
          isOpened={isCancelBlockOpened}
          actionHandler={cancelSessionHandler}
          closeHandler={() => setIsCancelBlockOpened(false)}
        >
          {translate('pages.orderAdd.cancelSessionText')}
        </PopupAction>
      </Stack>
    </Stack>
  );
}

export default React.memo(
  OrderAddFromSessionForm,
  (pp, np) => pp.orderSession?.updatedAt.getTime() === np.orderSession?.updatedAt.getTime()
);
