import DIContainer from 'services/DIContainer';
import ILanguageService from 'services/language/ILanguageService';
import { UnregisteredUserResponseDTO, UserResponseDTO } from 'typings/dto/user';
import { UserEnterpriseResponseDTO } from 'typings/dto/userEnterprise';
import { UserPlatformOperatorResponseDTO } from 'typings/dto/userPlatformOperator';
import { UserSPResponseDTO } from 'typings/dto/userSP';
import { UserSuperadminResponseDTO } from 'typings/dto/userSuperadmin';
import { ACCOUNT_TYPE, USER_POSITION, USER_ROLE } from 'typings/models/user.enum';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import FunctionUtils from 'utils/Function';
import NavigateFrontendUtils from 'utils/NavigateFrontend';

export default class UserUtils {
  // Тут возможно будет логика что рядом с position надо выводить фирму
  public static getPositionStr(user: User, _languageService: ILanguageService) {
    return user.position.name;
  }

  public static getCompanyStr = (user: User, _languageService: ILanguageService) => {
    switch (user.type) {
      case TENANT_TYPE.platformOperator: {
        return user.platformOperator.name;
      }
      case TENANT_TYPE.enterprise: {
        return user.enterprise.name;
      }
      case TENANT_TYPE.serviceProvider: {
        return user.serviceProvider.name;
      }
      case TENANT_TYPE.superadmin: {
        return '';
      }
      default: {
        FunctionUtils.exhaustiveCheck(user);
      }
    }
  };

  public static getFullAddress(address: Partial<AddressWithPointEntity>, translate: (v: string) => string) {
    if (address.streetAddress) {
      let fullAddress = address.streetAddress;

      if (address.additionalInfo && address.additionalInfo.fullAddress) {
        fullAddress = address.additionalInfo.fullAddress;
      }

      if (address.apartmentAddress) {
        fullAddress += ', ' + address.apartmentAddress;
      }

      return fullAddress;
    } else if (address.geoPoint) {
      return `${address.geoPoint.lat}, ${address.geoPoint.lon}`;
    } else {
      return translate('pages.order.emptyAddressText');
    }
  }

  public static mapUserResponseByType = (userResponseDTO: UserResponseDTO, mappers: DIContainer.Mappers['models']): User => {
    switch (userResponseDTO.tenant.type) {
      case TENANT_TYPE.platformOperator: {
        return mappers.userPlatformOperator.responseDTOToModel(userResponseDTO as UserPlatformOperatorResponseDTO);
      }
      case TENANT_TYPE.enterprise: {
        return mappers.userEnterprise.responseDTOToModel(userResponseDTO as UserEnterpriseResponseDTO);
      }
      case TENANT_TYPE.superadmin: {
        return mappers.userSuperadmin.responseDTOToModel(userResponseDTO as UserSuperadminResponseDTO);
      }
      case TENANT_TYPE.serviceProvider: {
        return mappers.userSP.responseDTOToModel(userResponseDTO as UserSPResponseDTO);
      }
      case TENANT_TYPE.user: {
        throw new Error('User tenant type is not valid: ' + TENANT_TYPE.user);
      }
      default: {
        FunctionUtils.exhaustiveCheck(userResponseDTO.tenant.type);
        throw new Error('User tenant type is not valid: ' + userResponseDTO.tenant.type);
      }
    }
  };

  // /** @param includeSpecial на данный момент отвечает за technician у ритейлера */
  // public static getAllowedPositions = (
  //   tenantType: TENANT_TYPE,
  //   langService: ILanguageService,
  //   includeSpecial?: boolean
  // ): EntityWithName[] => {
  //   const { translate } = langService;

  //   switch (tenantType) {
  //     case TENANT_TYPE.serviceProvider: {
  //       return [
  //         { id: USER_POSITION.admin, name: translate(`entities.user.positions.${USER_POSITION.admin}`) },
  //         { id: USER_POSITION.manager, name: translate(`entities.user.positions.${USER_POSITION.manager}`) },
  //         {
  //           id: USER_POSITION.technician,
  //           name: translate(`entities.user.positions.${USER_POSITION.technician}`),
  //         },
  //       ];
  //     }
  //     case TENANT_TYPE.po: {
  //       return [];
  //     }
  //     case TENANT_TYPE.enterprise: {
  //       const response = [
  //         { id: USER_POSITION.admin, name: translate(`entities.user.positions.${USER_POSITION.admin}`) },
  //         { id: USER_POSITION.manager, name: translate(`entities.user.positions.${USER_POSITION.manager}`) },
  //       ];
  //       if (includeSpecial) {
  //         response.push({
  //           id: USER_POSITION.technician,
  //           name: translate(`entities.user.positions.${USER_POSITION.technician}`),
  //         });
  //       }
  //       return response;
  //     }
  //     case TENANT_TYPE.user: {
  //       return [];
  //     }
  //     default: {
  //       FunctionUtils.exhaustiveCheck(tenantType);
  //       return [];
  //     }
  //   }
  // };

  public static isUnregisteredModel = (user: User | User.Unregistered) => {
    return user.accountType !== ACCOUNT_TYPE.user;
  };

  public static isUnregisteredDTO = (user: UserResponseDTO | UnregisteredUserResponseDTO) => {
    return user.userType !== ACCOUNT_TYPE.user;
  };

  /** Готовит LocationSearchObject для запроса по роли */
  // Дженерик маппера должен соответствовать
  public static getUserPositionLocationSearchObject = (role: USER_POSITION): { 'position.technicalName': LocationSearchParam } => {
    return {
      'position.technicalName': NavigateFrontendUtils.createLocationSearchParam(role),
    };
  };

  /** Готовит LocationSearchObject для запроса по роли */
  // Дженерик маппера должен соответствовать
  public static getUserRoleLocationSearchObject = (role: USER_ROLE): { 'businessRoles.name': LocationSearchParam } => {
    return {
      'businessRoles.name': NavigateFrontendUtils.createLocationSearchParam(role),
    };
  };

  /** Готовит LocationSearchObject для запроса по position != technician */
  // Дженерик маппера должен соответствовать
  public static getPositionExceptTechnicianLocationSearchObject = (): { position: LocationSearchParam } => {
    const locationSearchObject: Partial<Record<keyof User, LocationSearchParam>> = {
      position: NavigateFrontendUtils.createLocationSearchParam(USER_POSITION.technician, 'ne'),
    };
    return locationSearchObject as { position: LocationSearchParam };
  };

  /** Готовит LocationSearchObject для запроса по isActive от true к false */
  public static getSortedByActiveInDescOrderLocationSearchObject = () => {
    const locationSearchObject = {
      sortOrder: NavigateFrontendUtils.createLocationSearchParam(NavigateFrontendUtils.SORT_ORDER.desc),
      sortedBy: NavigateFrontendUtils.createLocationSearchParam('isActive')
    };
    return locationSearchObject;
  }
}
