import { CalendarVisiIntervalRequestDTO, CalendarVisitIntervalResponseDTO } from 'typings/dto/calendarVisitInterval';
import Mapper from './Mapper';
import DateUtils from 'utils/Date';
import DIContainer from 'services/DIContainer';

export default class CalendarVisitIntervalMapper extends Mapper<CalendarVisitInterval, CalendarVisitIntervalResponseDTO, CalendarVisiIntervalRequestDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, private dateUtils: DateUtils) {
    super({
      number: 'orderNumber',
      type: 'orderType',
      visitIntervals: 'visitDateIntervals',
      technicianTeam: 'technicianTeam',
      serviceProvider: 'serviceProvider',
      numberOfTechnicians: 'numberOfTechnicians',
      platformOperator: 'platformOperator',
      technicians: 'technicians',
      region: 'region',
    }, {
      visitDateIntervals: 'visitIntervals',
      allDay: null,
      end: null,
      numberOfTechnicians: null,
      orderId: null,
      from: null,
      orderNumber: null,
      orderType: null,
      platformOperator: null,
      region: null,
      serviceProvider: null,
      start: null,
      technicians: null,
      technicianTeam: null,
      to: null
    });
  }

  // внутри ActionsService метод modelApiService.getList переопределен
  public responseDTOToModel(order: CalendarVisitIntervalResponseDTO): any {
      return order.visitIntervals.map(visitInterval => {
        let visit = this.subEntitiesMappers.dateInterval.responseDTOToModel(visitInterval, order.region.timezone);
        const resourceIds = order.technicianTeam ? [order.technicianTeam.id] : order.serviceProvider ? [order.serviceProvider.id] : []
        order.technicians?.forEach(technician => resourceIds.push(technician.id))
        return ({
          ...this.responseDTOToModelViaDiffMap(order),
          ...visit,
          id: visit.id,
          resourceIds,
          start: visit.from,
          end: visit.to,
          orderTimezone: order.region.timezone,
          orderType: order.type,
          orderId: order.id,
          orderNumber: order.number,
        })
      })
  }
}