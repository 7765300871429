import { Box, Dialog, Stack, Typography } from '@mui/material';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import useDI from 'hooks/useDI';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';
import { CloseIcon, OrderClientsAddressIcon } from 'components/ui/icons';
import { useFormik } from 'formik';
import FormProvider from 'components/ui/forms/FormProvider';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import AddressMapUtils from 'utils/components/AddressMap';
import { PopupTransition } from 'components/ui/popups/PopupContent';
import { AddressWithPointDTO } from 'typings/dto/subEntity/address';
import useIsMountedRef from 'hooks/useIsMountedRef';
import SelectSemiSimpleMemorized from 'components/ui/forms/SelectSemiSimpleMemorized';
import Spinner from 'components/ui/loadingIndicators/Spinner';
import { ORDER_CLIENT_ROOM_TYPE } from 'typings/models/order/order.enum';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';

const MAP_CONTAINER_ID = 'order-address-map-container';

type Props = {
  isOpened: boolean;
  regionName?: string;
  address: Partial<AddressWithPointEntity>;
  closeHandler: VoidFunction;
  isEditable: boolean;
  isMultipleOrderCreation: boolean;
  /** Следует ли при открытии карты автоматически проставлять гео точку на основании адреса, если изначально она не передана */
  shouldInitializeGeoPoint?: boolean;
  /** closeHandler внутри submitHandler не нужен */
  submitHandler: (addressDTO: AddressWithPointDTO) => Promise<void> | void;
  title: string;
};

/** Новый компонент для поиска адреса через карту и новой типизации адреса
 * Сделан с той же реализацией, что при клике на карту значение адреса и геоточки устанавливается в инпут, так как пока не понятно останется ли где то в системе старая логика
 */
function SearchAddressMapPupup(props: Props) {
  return (
    <Dialog
      TransitionComponent={PopupTransition}
      PaperProps={{
        sx: {
          width: { xs: '100%', md: '70%' },
          height: { xs: '100%', md: '70%' },
          maxWidth: '100%',
          borderRadius: { xs: '0px !important', md: '16px !important' },
        },
      }}
      open={props.isOpened}
      onClose={props.closeHandler}
      scroll="body"
    >
      <AddressMapContent {...props} />
    </Dialog>
  );
}

// Сделано отдельным элементом, чтобы при каждом открытии форма пересоздавалась заново и подтягивала актуальные данные
function AddressMapContent({
  address,
  submitHandler,
  shouldInitializeGeoPoint = Boolean(address.geoPoint),
  isMultipleOrderCreation = false,
  closeHandler,
  isEditable: isMapEditable,
  regionName,
  title,
}: Props) {
  const { services, mappers } = useDI();
  const isMountedRef = useIsMountedRef();
  const translate = services.language.translate;
  const snackbarErrorHandler = useSnackbarErrorHandler();
  const initialStreetAddressRef = useRef(address.streetAddress || '');
  const streetAddressFieldName = 'streetAddress';
  const geoPointFieldName = 'geoPoint';
  const { streetAddress, apartmentAddress, geoPoint, additionalInfo } = address;
  const [hasNotAddress, setHasNotAddress] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(false);

  const roomTypeOptions = useMemo(() => {
    return [
      { id: ORDER_CLIENT_ROOM_TYPE.apartment, name: translate('common.address.roomType.apartment') },
      { id: ORDER_CLIENT_ROOM_TYPE.house, name: translate('common.address.roomType.house') },
      { id: ORDER_CLIENT_ROOM_TYPE.office, name: translate('common.address.roomType.office') },
    ];
  }, []);

  const initialValues = {
    [streetAddressFieldName]: initialStreetAddressRef.current,
    apartmentAddress: apartmentAddress || '',
    [geoPointFieldName]: geoPoint || null,
    roomType: additionalInfo?.roomType || ('' as ORDER_CLIENT_ROOM_TYPE),
    apartment: additionalInfo?.apartment || '',
    entrance: additionalInfo?.entrance || '',
    office: additionalInfo?.office || '',
    floor: additionalInfo?.floor || '',
    distanceToCity: additionalInfo?.distanceToCity || 0,
  };

  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const validationSchema = Yup.object().shape({
    roomType: isMultipleOrderCreation ? Yup.string() : Yup.string().required(fieldIsRequiredText),
    distanceToCity: Yup.number(),
    apartment: Yup.string().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) => (testContext.parent.roomType === ORDER_CLIENT_ROOM_TYPE.apartment ? Boolean(thisValue) : true),
    }),
    entrance: Yup.string().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) => (testContext.parent.roomType === ORDER_CLIENT_ROOM_TYPE.apartment ? Boolean(thisValue) : true),
    }),
    office: Yup.string().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) => (testContext.parent.roomType === ORDER_CLIENT_ROOM_TYPE.office ? Boolean(thisValue) : true),
    }),
    floor: Yup.string().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) =>
        testContext.parent.roomType === ORDER_CLIENT_ROOM_TYPE.apartment || testContext.parent.roomType === ORDER_CLIENT_ROOM_TYPE.office
          ? Boolean(thisValue)
          : true,
    }),
  });

  const formState = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        if (!address.geoPoint) {
          setHasNotAddress(true);
        }
        if (!address.geoPoint && !values[geoPointFieldName]) {
          return;
        }

        const { apartmentAddress, streetAddress, geoPoint, apartment, entrance, floor, office, roomType, distanceToCity } = values;
        let fullAddress: string;

        switch (roomType) {
          case ORDER_CLIENT_ROOM_TYPE.apartment: {
            fullAddress = `${streetAddress}, ${translate('common.address.roomType.apartment').toLowerCase()} ${apartment}, ${translate(
              'common.address.entrance'
            ).toLowerCase()} ${entrance}, ${translate('common.address.floor').toLowerCase()} ${floor}`;
            break;
          }
          case ORDER_CLIENT_ROOM_TYPE.office: {
            fullAddress = `${streetAddress}, ${translate('common.address.roomType.office').toLowerCase()} ${office}, ${translate(
              'common.address.floor'
            ).toLowerCase()} ${floor}`;
            break;
          }
          case ORDER_CLIENT_ROOM_TYPE.house: {
            fullAddress = streetAddress;
            break;
          }
          default: {
            fullAddress = streetAddress;
          }
        }

        const addressDTO: AddressWithPointDTO = mappers.subEntities.address.modelToRequestDTO({
          apartmentAddress: apartmentAddress || undefined,
          streetAddress,
          geoPoint: geoPoint || undefined,
          additionalInfo: {
            fullAddress,
            roomType: ORDER_CLIENT_ROOM_TYPE.b2b,
            apartment: apartment ? apartment : undefined,
            office: office ? office : undefined,
            entrance: entrance ? entrance : undefined,
            floor: floor ? floor : undefined,
            distanceToCity: distanceToCity ? distanceToCity : undefined,
          },
        });

        await submitHandler(addressDTO);
        if (isMountedRef.current) closeHandler();
      } catch (error) {
        snackbarErrorHandler({ error, callback: () => setSubmitting(false) });
      }
    },
  });

  const orderMapUtils = useMemo(() => {
    const errorHandler = (error: any) => snackbarErrorHandler({ error });
    return new AddressMapUtils(
      services.geo,
      services.language,
      services.map.requestScope(),
      errorHandler,
      geoPointFieldName,
      formState,
      streetAddressFieldName,
      isMapEditable,
      regionName,
      setIsAddressLoading
    );
  }, []);

  useEffect(() => {
    const geoPointRequest = shouldInitializeGeoPoint
      ? orderMapUtils.initializeGeoPoint(streetAddress, geoPoint)
      : Promise.resolve(undefined);
    geoPointRequest
      .then((geoPointInitialized) => orderMapUtils.initializeMap(MAP_CONTAINER_ID, geoPointInitialized))
      .catch((error) => snackbarErrorHandler({ error }));
  }, []);

  return (
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      sx={{ width: '100%', height: '100%', backgroundColor: 'white', borderRadius: { xs: 0, md: 2 }, p: { xs: 0, md: 2 } }}
    >
      <FormProvider
        formState={formState}
        fullWidth
        sx={{ gap: 2 }}
        containerSx={{ flex: 0, p: { xs: 2, md: 0 }, pr: (theme) => theme.spacing(2) + '!important' }}
      >
        <Typography variant="h6">{title}</Typography>

        {/* Если пользователь поменял адрес кликом по карте, а не через поиск, то нужен лоадер, т.к. в этом случае делается запрос за адресом по геоточке и без флага загрузки адрес обновляется позже чем карта и блок с координатами */}
        <Stack spacing={1}>
          {isAddressLoading ? (
            <Spinner sx={{ py: 2 }} />
          ) : (
            <>
              {formState.values[streetAddressFieldName] && (
                <Typography variant="body2" color={'text.secondary'}>
                  {formState.values[streetAddressFieldName]}
                </Typography>
              )}
              {formState.values[geoPointFieldName] && (
                <Typography variant="body2" color={'text.secondary'}>
                  {translate('common.address.geoPoint') +
                    ': ' +
                    formState.values[geoPointFieldName]?.lat +
                    ', ' +
                    formState.values[geoPointFieldName]?.lon}
                </Typography>
              )}
            </>
          )}
        </Stack>

        {!formState.values[geoPointFieldName] && hasNotAddress && (
          <Stack flexDirection="row" gap={1} alignItems="center">
            <OrderClientsAddressIcon sx={{ color: (theme) => theme.palette.error.main }} />
            <Typography variant="body2" color="error.main">
              {translate('errors.addressRequired')}
            </Typography>
          </Stack>
        )}

        {!isMultipleOrderCreation && <SelectSemiSimpleMemorized
          formState={formState}
          fieldName="roomType"
          options={roomTypeOptions}
          label={translate('common.address.roomTypeTitle')}
          onChange={(e) => {
            formState.setFieldValue('roomType', e.target.value);
            formState.setFieldValue('apartment', '');
            formState.setFieldValue('entrance', '');
            formState.setFieldValue('office', '');
            formState.setFieldValue('floor', '');
          }}
        />}
        {!isMultipleOrderCreation && <TextFieldMemorized fieldName="distanceToCity" formState={formState} label={translate('common.address.distanceToCity')} />}
        <TextFieldMemorized fieldName="apartmentAddress" label={translate('entities.order.clientsAddressLine2')} formState={formState} />

        {formState.values.roomType === ORDER_CLIENT_ROOM_TYPE.apartment && (
          <>
            <TextFieldMemorized fieldName="apartment" formState={formState} label={translate('common.address.roomType.apartment')} />
            <TextFieldMemorized fieldName="entrance" formState={formState} label={translate('common.address.entrance')} />
          </>
        )}

        {formState.values.roomType === ORDER_CLIENT_ROOM_TYPE.office && (
          <TextFieldMemorized fieldName="office" formState={formState} label={translate('common.address.roomType.office')} />
        )}

        {(formState.values.roomType === ORDER_CLIENT_ROOM_TYPE.apartment ||
          formState.values.roomType === ORDER_CLIENT_ROOM_TYPE.office) && (
            <TextFieldMemorized fieldName="floor" formState={formState} label={translate('common.address.floor')} />
          )}

        <ConfirmButtonGroup isLoading={formState.isSubmitting} cancelHandler={closeHandler} />
      </FormProvider>

      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          id={MAP_CONTAINER_ID}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: { xs: 0, md: 2 },
            'input.ymaps-2-1-79-searchbox-input__input': { height: '45px', fontSize: '16px' },
            '.ymaps-2-1-79-searchbox-button': {
              height: '45px',
              '.ymaps-2-1-79-searchbox-button-text': {
                fontSize: '16px',
                padding: '8px 8px',
              },
            },
          }}
        />
      </Box>
      <IconButton
        sx={{
          backgroundColor: 'white',
          position: 'absolute',
          top: (theme) => ({ xs: theme.spacing(2), md: theme.spacing(3) }),
          right: (theme) => ({ xs: theme.spacing(2), md: theme.spacing(3) }),
          '&:hover': { backgroundColor: 'white' },
        }}
        onClick={closeHandler}
        title={translate('buttons.close')}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
}

export default React.memo(
  SearchAddressMapPupup,
  (pp, np) =>
    pp.isOpened === np.isOpened &&
    pp.address.geoPoint?.lat === np.address.geoPoint?.lat &&
    pp.address.geoPoint?.lon === np.address.geoPoint?.lon
);
